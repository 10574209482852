var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          _vm.getHelperObject('group', 'common_area_group_id', 'common_area_groups'),
          _vm.helpers.currencyValue,
          _vm.getHelperObject('status', 'common_area_status_id', 'common_area_statuses'),
          'concurrency',
          'max_reservation',
          Object.assign({}, _vm.helpers.validIcons, {key: 'all_day'}),
          //{ ...helpers.datetimeTime, key: 'min_time', helper: { ...helpers.datetimeTime.helper, id: 'min_time' } },
          //{ ...helpers.datetimeTime, key: 'max_time', helper: { ...helpers.datetimeTime.helper, id: 'max_time' } }
          //{ ...helpers.simpleTime, key: 'min_time' },
          //{ ...helpers.simpleTime, key: 'max_time' }
          Object.assign({}, _vm.helpers.simpleTime, {key: 'min_time', render: function (time) { return (time ? _vm.$m(time, 'HH:mm').format('h:mm a') : '-'); }}),
          Object.assign({}, _vm.helpers.simpleTime, {key: 'max_time', render: function (time) { return (time ? _vm.$m(time, 'HH:mm').format('h:mm a') : '-'); }})
        ],"config":{
          url: 'admin/common_areas',
          route: '/admin/tools/common_areas',
          name: 'common_area',
          params: { _lists: 'common_area_groups,common_area_statuses' }
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }