<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            getHelperObject('group', 'common_area_group_id', 'common_area_groups'),
            helpers.currencyValue,
            getHelperObject('status', 'common_area_status_id', 'common_area_statuses'),
            'concurrency',
            'max_reservation',
            { ...helpers.validIcons, key: 'all_day' },
            //{ ...helpers.datetimeTime, key: 'min_time', helper: { ...helpers.datetimeTime.helper, id: 'min_time' } },
            //{ ...helpers.datetimeTime, key: 'max_time', helper: { ...helpers.datetimeTime.helper, id: 'max_time' } }
            //{ ...helpers.simpleTime, key: 'min_time' },
            //{ ...helpers.simpleTime, key: 'max_time' }
            { ...helpers.simpleTime, key: 'min_time', render: time => (time ? $m(time, 'HH:mm').format('h:mm a') : '-') },
            { ...helpers.simpleTime, key: 'max_time', render: time => (time ? $m(time, 'HH:mm').format('h:mm a') : '-') }
          ]"
          :config="{
            url: 'admin/common_areas',
            route: '/admin/tools/common_areas',
            name: 'common_area',
            params: { _lists: 'common_area_groups,common_area_statuses' }
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'CommonAreas',
  mixins: [crudMixin]
}
</script>
